import React, { useEffect, setState } from 'react';
import _, { bind } from 'lodash';
import {graphql} from 'gatsby';

import components, {Layout} from '../components/index';
import FormField from '../components/FormField';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField from '@material-ui/core/TextField';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

// add aws-cli into aplication and import models
import Amplify, { API, graphqlOperation, Auth, DataStore, Predicates }  from "aws-amplify";
import awsConfig from "../aws-exports";
import { AmplifyAuthenticator, AmplifySignUp, AmplifySignIn, AmplifySignOut } from '@aws-amplify/ui-react'
import { withAuthenticator } from "aws-amplify-react"
import { Post, User, Pet, Preference } from '../models';

import * as Queries from '../graphql/queries'
import { 
  createUser as CreateUser, 
  createPet as CreatePet,
  createPreference as CreatePreference, 
  createReminder as CreateReminder, 
  updateReminder as UpdateReminder,
  updateUser as UpdateUser,
  deleteReminder as DeleteReminder
} from '../graphql/mutations'

Amplify.configure(awsConfig); 

import { v4 as uuidv4 } from 'uuid'

// this minimal GraphQL query ensures that when 'gatsby develop' is running,
// any changes to content files are reflected in browser
export const query = graphql`
  query($url: String) {
    sitePage(path: {eq: $url}) {
      id
    }
  }
`;

const typeRem = 2

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

Auth.currentAuthenticatedUser()
  .then(data => {
    console.log("Auth.currentAuthenticatedUser", data)
    
  })
  .catch(err => console.log(err));

const myListPets=`
  query {
    listPets {
      items {
        id
        name_pet
        name_vet
        color_pet
      }
    }
  }
`

class Advanced extends React.Component {
  constructor(props){
    super(props)
    this.handleItem = this.handleItem.bind(this)
    this.handleRadio = this.handleRadio.bind(this)
    
    this.submit     = this.submit.bind(this)
    
    this.handleClickAlert = this.handleClickAlert.bind(this);
    this.handleCloseAlert = this.handleCloseAlert.bind(this);

    this.setLocal   = this.setLocal.bind(this)
    this.getLocal   = this.getLocal.bind(this)
    this.API        = this.API
    this.state= {
      alertShow: false,
      alertShowMessage: "Reminder saved!",
      userInfo: {
        reminderTime: ''
      },
      valueItems : {},
      newSavedUser : {},
      newSavedPet: {},
      selectedValue: "",
      selectedValidation: false,
      isValidation: false,
      massValidation: {
        nameNotification: true,
        repeat: true,
        reminderTime: true,
        reminderDayStart: true,
        reminderDayEnd: true
      }
    }
    
  }

      handleClickAlert = () => {
        this.setState({alerShow: true})
      };

      handleCloseAlert = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }

        this.setState({alerShow: false})
      };
      
  
      async setLocal(addr, data, param) {
        if (param) {
          localStorage.setItem(addr, JSON.stringify(data))
        } else {
          localStorage.setItem(addr, data)
        }
        return true
      }

      async getLocal(addr, param, setSt) {
        let fromStorage = await localStorage.getItem(addr) 
        if (fromStorage == null) return false
        let dataFromStorage = (param)? JSON.parse(fromStorage) : fromStorage
        // console.log(dataFromStorage);
        
        (setSt) ? this.setState(dataFromStorage) : null
        return dataFromStorage
      }

      async deletePreviousReminders(user, param) {
        console.log('user', user);
        let allReminders = await API.graphql(
          graphqlOperation(Queries.listReminders, {
            filter: { notification_type: { eq: param}, or: [
              {notification_email: {eq: user.email}},
              {notification_phone: {eq: user.mobile_user}}
            ]},
          })
        );
        let data = allReminders.data.listReminders.items
        data.forEach(reminder => {
          API.graphql(graphqlOperation(DeleteReminder, { input: { id: reminder.id } }));
          console.log(reminder.id);
        });
        console.log('allReminders', data);
      }

      submit = async (event) => {
        // let {nameNotification, repeat, typeChoise, reminderTime, reminderDayStart, reminderDayEnd } = this.massValidation
        console.log("this.state.userInfo", this.state);
        event.preventDefault();
        let userID = this.state.userInfo
        await this.deletePreviousReminders(userID, 2)
        let v = this.state.massValidation
        let validate = (v.nameNotification 
                    || v.repeat
                    || v.reminderTime
                    || v.reminderDayStart
                    || v.reminderDayEnd
                    || this.state.selectedValidation);
        console.log("validation", validate, v);
        console.log("submit", event);
        console.log("this.state", this.state);
        if (validate == true) return this.setState({isValidation: true})
        try {
          let repeat = Number(this.state.valueItems.repeat)
          let startTime = this.state.valueItems.reminderTime.split(":")
          let curTime = Number(startTime[0])
          let arrayTime = []
          let savedReminder = {}
          let updateUser = {
            id: this.state.userInfo.id
          }
          do {
            let newTime = `${curTime}:${startTime[1]}`
            await arrayTime.push(newTime)
            curTime = Number(curTime) + repeat;
            console.log("curTime", this.state.valueItems.reminderTime, curTime, newTime, typeof(curTime));

          } while (curTime < 24);

          console.log("repeat, startTime", repeat, startTime, arrayTime);
          await arrayTime.forEach(async (time) => {
            console.log("TIME: ", time);
            let newReminder = { 
              name_reminder:      this.state.valueItems.nameNotification || "",
              frequency:          this.state.selectedValue || "",
              repeat:             this.state.valueItems.repeat || "",
              start_date:         this.state.valueItems.reminderDayStart || "",
              end_date:           this.state.valueItems.reminderDayEnd || "",
              share_reminder:     this.state.valueItems.reminderShare || false,
              notification_time:  time,
              notification_phone: this.state.userInfo.mobile_user,
              notification_email: this.state.userInfo.email,
              reminderUserId:     this.state.userInfo.id,
              notification_type:  typeRem
            }
            console.log('newReminder', newReminder);
            // if (this.state.userInfo.Reminder == null) {
            console.log("new reminder");
            savedReminder = await API.graphql(graphqlOperation(CreateReminder, { input: newReminder }  ))
              .then( async(savedReminder) => {
                console.log('savedReminder.data.createReminder:', savedReminder.data.createReminder);
                console.log("updateUser", updateUser, savedReminder.data.createReminder);
                updateUser.userReminderPrescriptionId = savedReminder.data.createReminder.id
                let savedUser = await API.graphql(graphqlOperation(UpdateUser, { input: updateUser }  ))

              })
            
          })
           
          
          await this.setState({alerShow: true})
          // } else {
          //   console.log("old reminder");
          //   newReminder.id = this.state.userInfo.Reminder.id;
          //   let savedReminder = await API.graphql(graphqlOperation(UpdateReminder, { input: newReminder }  ))
          //   console.log('savedReminder.data.createReminder:', savedReminder.data);
          //   this.setState({alerShow: true})
          // }
          
        } catch (error) {
          console.log("ERROR API.GRAPHQL");
        } finally {
          
          // window.location.assign(`/`)
        }
        
        
      }

      handleItem(event) {
        console.log('!!!', event.target);
        let newStateItems = this.state.valueItems
        let newValidation = this.state.massValidation
        let ItemType = event.target.type;
        let newValue
        if ( ItemType == 'checkbox' ) {
          newValue = event.target.checked
        } else {
          newValue = event.target.value
        }
        newStateItems[`${event.target.name}`] = newValue;
        newValidation[`${event.target.name}`] = (newValue == "") ? true : false
        this.setState({valueItems: newStateItems, massValidation: newValidation})

        console.log("this.state", this.state)
        console.log("this.state.massValidation", this.state.massValidation)
        
        // console.log('this.state = ', this.state.valueItems);
      }

      handleRadio(event) {
        console.log(this.state);
      
        console.log('!!!', event.target);
        // this.state.selectedValue = event.target.value;
        this.setState({selectedValue: event.target.value, selectedValidation: false})
        console.log("this.state.selectedValue", this.state.selectedValue);
      }

      UNSAFE_componentWillMount = async () => {
        // console.log(Queries.getUser);
        await this.getLocal('state', true, true)
        // console.log("state after temper = ", this.state.newSavedPet);
        
        // let data = await API.graphql(graphqlOperation(Queries.listPets))
        // console.log('pets in data = ', data.data);
        
        // data = await API.graphql(graphqlOperation(Queries.listUsers))
        // console.log('users in data = ', data.data.listUsers);
        
        console.log('======>>: ', await Auth.currentAuthenticatedUser());
        await Auth.currentAuthenticatedUser()
          .then(async (user) => {
            // return Auth.changePassword(user, 'oldPassword', 'newPassword');
            console.log('user.name:', user.username);
            let data = await API.graphql(graphqlOperation(Queries.getUser, {id: user.username}))
            await this.setLocal('userId', user.username, false);
            // await this.setState({userInfo: data.data.getUser})
            console.log('GetUser in data = ', data.data.getUser)
            let reminder = data.data.getUser.ReminderPrescription
            if (data.data.getUser == null || data.data.getUser == undefined) {
              
              let newUser = {
                id :          user.username,
                email:        user.attributes.email,
              }
              let savedUser = await API.graphql(graphqlOperation(CreateUser, { input: newUser }  ))
              console.log("savedUser.data: ,savedUser.data");
              
            } else {
              console.log("this users email saved in cognito:", user.attributes.email);
              console.log('reminder from User', reminder);
              let newStateItems = this.state.valueItems;
              let newMassValidation = this.state.massValidation;
              console.log("newStateItems", newStateItems);
              console.log("newMassValidation", newMassValidation);
              

              if (reminder) {
                if (reminder.name_reminder) {
                  newStateItems['nameNotification'] = reminder.name_reminder;
                  newMassValidation['nameNotification'] = false;
                }
                if (reminder.repeat) {
                  newStateItems['repeat'] = reminder.repeat;
                  newMassValidation['repeat'] = false;
                }
                if (reminder.start_date) {
                  newStateItems['reminderDayStart'] = reminder.start_date;
                  newMassValidation['reminderDayStart'] = false;
                }
                if (reminder.end_date) {
                  newStateItems['reminderDayEnd'] = reminder.end_date;
                  newMassValidation['reminderDayEnd'] = false;
                }
                if (reminder.remindersShare) {
                  newStateItems['remindersShare'] = reminder.remindersShare;
                  newMassValidation['remindersShare'] = false;
                }
                if (reminder.notification_time) {
                  newStateItems['reminderTime'] = reminder.notification_time;
                  newMassValidation['reminderTime'] = false;
                }

              }
              
              console.log("newStateItems", newStateItems);
              console.log("newMassValidation", newMassValidation);
              console.log("this.state", this.state);
              this.setState({
                valueItems: newStateItems,
                selectedValue: (reminder) ? reminder.frequency : "",
                isValidation: false,
                massValidation: newMassValidation,
                userInfo: data.data.getUser
              })
            }
          })
          .catch(err => console.log(err));
      }

      render() {
        let sectionsInfo = _.get(this.props, 'pageContext.frontmatter.sections', null)
      
        // console.log('tut props = ', sectionsInfo)
        let section = sectionsInfo[0];
        // console.log('section first = ', section);
        return (
          <AmplifyAuthenticator usernameAlias="email">
              <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={this.state.alerShow} autoHideDuration={6000} onClose={this.handleCloseAlert}>
                <Alert onClose={this.handleCloseAlert} severity="success">
                  {this.state.alertShowMessage}
                </Alert>
              </Snackbar>
              {/* <Alert severity="error">This is an error message!</Alert>
              <Alert severity="warning">This is a warning message!</Alert>
              <Alert severity="info">This is an information message!</Alert>
              <Alert severity="success">This is a success message!</Alert> */}
            <AmplifySignOut />
            <AmplifySignIn slot="sign-in" usernameAlias="email" />
            <Layout {...this.props}>
              
              {(!_.get(this.props, 'pageContext.frontmatter.hide_title', null)) && (
                <header className="page-header inner-sm outer">
                  <h1 className="page-title line-top">{_.get(this.props, 'pageContext.frontmatter.title', null)}</h1>
                </header>
              )}
              <div className="block-content inner">
                <section id={_.get(section, 'section_id', null)} className="block block-form outer">
                  <div className="inner">
                    {(_.get(section, 'title', null) || _.get(section, 'subtitle', null)) && (
                      <div className="block-header inner-sm">
                        {_.get(section, 'title', null) && (
                        <h2 className="block-title line-top">{_.get(section, 'title', null)}</h2>
                        )}
                        {_.get(section, 'subtitle', null) && (
                        <p className="block-subtitle">{htmlToReact(_.get(section, 'subtitle', null))}</p>
                        )}
                      </div>
                    )}
                  </div>
                  
                  <div className="block-content inner-sm">
                    <form name={_.get(section, 'form_id', null)} id={_.get(section, 'form_id', null)} onSubmit={this.submit} >
                        <div className="grid grid-col-2">
                          <div key="1" className="grid-item grid-margin-bottom">
                            <div className="form-group">
                              <TextField
                                error={this.state.isValidation && this.state.massValidation.nameNotification}
                                id="nameNotification"
                                label="Reminder Name"
                                name="nameNotification"
                                multiline
                                InputLabelProps={{
                                  shrink:  true
                                }}
                                rowsMax={4}
                                value={this.state.valueItems.nameNotification}
                                onChange={this.handleItem}
                              />
                            </div>
                            <FormControl error={this.state.isValidation&&this.state.selectedValidation} component="fieldset">
                              <FormLabel component="legend">Periodicity</FormLabel>
                              <RadioGroup 
                                aria-label="gender" 
                                name="typeChoise" 
                                value={this.state.selectedValue}
                                onChange={this.handleRadio}
                              >
                                <FormControlLabel value="Daily" control={<Radio />} label="Daily" />
                                <FormControlLabel value="Weekly" control={<Radio />} label="Weekly" />
                                <FormControlLabel value="Monthly" control={<Radio />} label="Monthly" />
                              </RadioGroup>
                            </FormControl>
                            <div className="form-group">
                              <TextField
                                error={this.state.isValidation&&this.state.massValidation.repeat}
                                id="repeat"
                                label="Repeat (hours)"
                                name="repeat"
                                multiline
                                InputLabelProps={{
                                  shrink:  true
                                }}
                                type="number"
                                rowsMax={4}
                                value={this.state.valueItems.repeat}
                                onChange={this.handleItem}
                              />
                            </div>
                          </div>
                          <div key="2" className="grid-item grid-margin-bottom">
                            <div className="form-group">
                              <TextField
                                error={this.state.isValidation&&this.state.massValidation.reminderTime}
                                id="reminderTime"
                                name="reminderTime"
                                label="Time"
                                type="time"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                inputProps={{
                                  step: 60, // 5 min
                                }}
                                onChange={this.handleItem}
                                value={this.state.valueItems.reminderTime || ''}
                              />
                            </div>
                            <div className="form-group">
                              <TextField
                                error={this.state.isValidation&&this.state.massValidation.reminderDayStart}
                                id="reminderDayStart"
                                name="reminderDayStart"
                                label="First day"
                                type="date"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                onChange={this.handleItem}
                                value={this.state.valueItems.reminderDayStart || ''}
                              />
                            </div>
                            <div className="form-group">
                              <TextField
                                error={this.state.isValidation&&this.state.massValidation.reminderDayEnd}
                                id="reminderDayEnd"
                                name="reminderDayEnd"
                                label="Last day"
                                type="date"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                onChange={this.handleItem}
                                value={this.state.valueItems.reminderDayEnd || ''}
                              />
                            </div>
                            {/* <div className="form-group form-checkbox">
                              <input onChange={this.handleItem} type="checkbox" id="remindersShare" name="remindersShare" />
                              <label style={{margin:5}} htmlFor="remindersShare">Share Reminder</label>
                            </div>  */}
                          </div>
                        </div>
                        <div className="form-submit">
                          <button type="submit" style={{margin:10}} name="buttonSave" className="button">{_.get(section, 'submit_label', null)}</button>
                        </div>
                    </form>
                  </div> 
                </section>
              </div>
            </Layout>
          </AmplifyAuthenticator>
        );
    }
}

export default Advanced